import React from 'react';

function ShareButton() {
  return (
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5322 11.1365C13.0318 11.1377 12.5388 11.2572 12.0933 11.4853C11.6479 11.7134 11.2628 12.0436 10.9693 12.449L6.98023 9.78983C7.23162 9.11954 7.23162 8.38086 6.98023 7.71056L10.9693 5.05142C11.4171 5.65844 12.0666 6.08591 12.8013 6.25701C13.5359 6.42811 14.3075 6.33163 14.9773 5.98491C15.6472 5.63819 16.1715 5.06396 16.456 4.36537C16.7405 3.66679 16.7665 2.88965 16.5295 2.17359C16.2924 1.45752 15.8078 0.849454 15.1626 0.458645C14.5175 0.0678357 13.7541 -0.0801035 13.0097 0.041407C12.2652 0.162918 11.5885 0.545913 11.1011 1.12158C10.6137 1.69725 10.3476 2.42786 10.3505 3.18215C10.3557 3.29805 10.3673 3.41357 10.3855 3.52816L6.0973 6.38696C5.64031 5.97499 5.07368 5.70437 4.46603 5.60785C3.85838 5.51133 3.23577 5.59306 2.67363 5.84315C2.11148 6.09324 1.6339 6.50095 1.29872 7.0169C0.963546 7.53286 0.785156 8.13493 0.785156 8.7502C0.785156 9.36547 0.963546 9.96754 1.29872 10.4835C1.6339 10.9995 2.11148 11.4072 2.67363 11.6572C3.23577 11.9073 3.85838 11.9891 4.46603 11.8926C5.07368 11.796 5.64031 11.5254 6.0973 11.1134L10.3855 13.9722C10.3673 14.0868 10.3557 14.2023 10.3505 14.3183C10.3505 14.9475 10.5371 15.5627 10.8867 16.0859C11.2363 16.6092 11.7333 17.017 12.3146 17.2578C12.896 17.4986 13.5358 17.5616 14.153 17.4389C14.7702 17.3161 15.3371 17.0131 15.7821 16.5681C16.227 16.1231 16.5301 15.5562 16.6528 14.939C16.7756 14.3218 16.7126 13.682 16.4718 13.1007C16.231 12.5193 15.8232 12.0223 15.2999 11.6727C14.7767 11.3231 14.1615 11.1365 13.5322 11.1365Z" fill="#E6E6E6"/>
</svg>
  );
}

export default ShareButton;