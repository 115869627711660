import React from 'react';

function PauseIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="13" height="13" rx="2" fill="#E6E6E6"/>
    </svg>
  );
}

export default PauseIcon;