import React from 'react';

const MainLogo = () => {
  const handleLogoClick = () => {
    window.location.href = '/'; // navigate to the homepage
    window.location.reload();   // force a page refresh
  };

  return (
    <a href="/" onClick={handleLogoClick}>      
      <svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" fill="transparent"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.19558 1H38.8044C41.6739 1 44 3.32614 44 6.19558V35.8044C44 38.6739 41.6739 41 38.8044 41H9.19558C6.32614 41 4 38.6739 4 35.8044V6.19558C4 3.32614 6.32614 1 9.19558 1ZM9.19558 3.59779C7.76086 3.59779 6.59779 4.76086 6.59779 6.19558V35.8044C6.59779 37.2391 7.76086 38.4022 9.19558 38.4022H38.8044C40.2391 38.4022 41.4022 37.2391 41.4022 35.8044V6.19558C41.4022 4.76086 40.2391 3.59779 38.8044 3.59779H9.19558Z" fill="#E6E6E6"/>
        <path id="coloredElement" d="M17.9983 34.474C16.0723 34.474 14.5574 34.0953 13.4537 33.3379C12.3717 32.5805 11.6143 31.5525 11.1815 30.2541C10.7486 28.934 10.5322 27.4191 10.5322 25.7095V16.3282C10.5322 14.532 10.7486 12.9739 11.1815 11.6538C11.6143 10.3337 12.3717 9.31662 13.4537 8.60247C14.5574 7.88832 16.0723 7.53125 17.9983 7.53125C19.8161 7.53125 21.2336 7.84504 22.2507 8.47263C23.2895 9.07857 24.0253 9.95502 24.4581 11.102C24.8909 12.2489 25.1073 13.5798 25.1073 15.0947V17.3021H20.4978V15.0298C20.4978 14.294 20.4545 13.6231 20.368 13.0172C20.303 12.4112 20.0975 11.9352 19.7512 11.5889C19.4266 11.221 18.8531 11.0371 18.0308 11.0371C17.2084 11.0371 16.6025 11.2318 16.2129 11.6214C15.845 11.9893 15.607 12.4978 15.4988 13.147C15.3906 13.7746 15.3365 14.4888 15.3365 15.2895V26.7158C15.3365 27.668 15.4122 28.4579 15.5637 29.0855C15.7152 29.6914 15.9857 30.1567 16.3752 30.4813C16.7864 30.7843 17.3383 30.9358 18.0308 30.9358C18.8315 30.9358 19.3941 30.7518 19.7187 30.3839C20.065 29.9944 20.2814 29.4858 20.368 28.8582C20.4545 28.2307 20.4978 27.5273 20.4978 26.7483V24.3786H25.1073V26.4886C25.1073 28.0467 24.9017 29.4317 24.4905 30.6436C24.0794 31.8338 23.3544 32.7752 22.3156 33.4677C21.2985 34.1386 19.8594 34.474 17.9983 34.474Z" fill="#F37878"/>
        <ellipse cx="32.3086" cy="12.6709" rx="5.13968" ry="5.13969" fill="#E6E6E6"/>
      </svg>
    </a>
  );
}; 

export default MainLogo;