import React, { useState, useEffect } from 'react';
import './IndexAlbums.css';
import PlayerFrame from './PlayerFrame';
import PlayIcon from './icons/PlayIcon';
import PauseIcon from './icons/PauseIcon';
import ShareButton from './icons/ShareButton';
import MainLogo from './icons/MainLogo';
import Analytics from './Analytics';
import Metadata from './Meta';

const PAGE_SIZE = 10;
const genres = ['Indie', 'Electronic', 'Rap', 'Pop', 'Rock', 'Folk', 'Jazz', 'Metal'];

const MainComponent = () => {
    const [items, setItems] = useState([]);
    const [visiblePlayerFrame, setVisiblePlayerFrame] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [installPrompt, setInstallPrompt] = useState(null);
    const [isPWAInstalled, setIsPWAInstalled] = useState(false); 
    const [category, setCategory] = useState(null); 
    const [activeCategory, setActiveCategory] = useState(null);
    const [isMobNavVisible, setIsMobNavVisible] = useState(false);
    const title = 'Chillmoon';
    const description = 'Explore the latest Indie Rock, Indie Pop, Dream Pop, and Shoegaze releases.';
    const ogTitle = 'Chillmoon';
    const ogDescription = 'Explore the latest Indie Rock, Indie Pop, Dream Pop, and Shoegaze releases.';
    const ogImageUrl = './chillmoon_opengraph.png';   

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            const params = new URLSearchParams(window.location.search);
            const albumId = params.get("album");
      
            let fetchUrl = `/albums?page=${currentPage}&pageSize=${PAGE_SIZE}`;
      
            if (albumId) {
              fetchUrl += `&id=${albumId}`;
            }
      
            if (category) {
              fetchUrl += `&cat=${category}`;
            }
      
            const response = await fetch(fetchUrl);
      
            if (!response.ok) {
              throw new Error('Failed to fetch data');
            }
      
            const data = await response.json();
            console.log(data);
            if (currentPage === 1) {
              setItems(data.albums.results);
            } else {
              setItems(prevItems => [...prevItems, ...data.albums.results]);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          setLoading(false);
        };
      
        fetchData();
      }, [currentPage, category]);

      useEffect(() => {
        const container = document.querySelector('.albumsIndex');
      
        function handleScroll() {
            const scrollTop = container.scrollTop;
            const scrollHeight = container.scrollHeight;
            const clientHeight = container.clientHeight;
            const bottom = scrollTop + clientHeight >= scrollHeight - 100;
            if (bottom && !loading) {
                setCurrentPage(currentPage => currentPage + 1);
            }
        }
      
        let timeoutId;
        function debouncedHandleScroll() {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleScroll, 200);
        }
      
        if (!loading) {
            container.addEventListener('scroll', debouncedHandleScroll);
        }
      
        return () => {
            container.removeEventListener('scroll', debouncedHandleScroll);
            clearTimeout(timeoutId);
        };
      }, [currentPage, loading]);
      
      const handleNavTriggerClick = (categoryId) => {
        if (categoryId === 'All Genres') {
            setCategory(null); 
            setActiveCategory(null);
            setIsMobNavVisible(false);
            setCurrentPage(1); // Reset to first page when selecting "All Genres"
        } else if (categoryId === 'Browse All The Genres') {
            setIsMobNavVisible(!isMobNavVisible); 
        } else {
            setCategory(categoryId);
            setCurrentPage(1); // Reset to first page when selecting a specific category
            const url = new URL(window.location.href);
            url.searchParams.delete('album');
            window.history.replaceState({}, '', url);
      
            setActiveCategory(categoryId); 
            setIsMobNavVisible(false); 
        }

        const container = document.querySelector('.albumsIndex');
        container.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: You can change the scroll behavior to smooth if desired
        });
      };
      

  const handlePlayClick = (item) => {
      setVisiblePlayerFrame(visiblePlayerFrame === item ? null : item);
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setInstallPrompt(event);
    };
  
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  
  useEffect(() => {
    const checkPWAInstallStatus = () => {
      const isInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
      setIsPWAInstalled(isInstalled);
    };
  
    window.addEventListener('appinstalled', checkPWAInstallStatus);
    checkPWAInstallStatus(); // Check initial status
  
    return () => {
      window.removeEventListener('appinstalled', checkPWAInstallStatus);
    };
  }, []);
  
  const handleInstallClick = () => {
    if (installPrompt) {
      installPrompt.prompt();
  
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
  
        setInstallPrompt(null);
      });
    }
  };

  const handleShareClick = (item) => {
    const shareUrl = window.location.origin + `?album=${item.AlbumID}`;
    if (navigator.share) {
      navigator.share({
        title: item.title,
        text: `Check out this album: ${item.title}`,
        url: shareUrl
      })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported');
    }
  };

const modifiedGenres = activeCategory ? ['All Genres', ...genres] : genres;

const currentUrl = window.location.href;

return (
    <div>
      <Metadata
        title={title}
        description={description}
        ogTitle={ogTitle}
        ogDescription={ogDescription}
        ogImageUrl={ogImageUrl}
      />
      <Analytics location={currentUrl} title={title}/>
        <div className='albumsIndex'>
            <div className='mainNav'>
                <div className='navElements'>
                    <MainLogo />
                    <div className='flexTriggers hidden'>
                        {genres.map((genre) => (
                            <div key={genre} className={`navTrigger ${activeCategory === genre ? 'activeTrigger' : ''}`} onClick={() => handleNavTriggerClick(genre)}>
                            {genre}
                            </div>
                        ))}
                    </div>
                </div>
            </div>  
            <div className='mobileNav'>
                {isMobNavVisible && (
                    <div className='mobNavTriggers'>
                        {modifiedGenres.map((genre) => (
                            <div key={genre} className={`navTrigger centered margT ${activeCategory === genre ? 'activeTrigger' : ''}`} onClick={() => handleNavTriggerClick(genre)}>
                            {genre}
                            </div>
                        ))}
                    </div>
                )}
                <div className='navTrigger mob' id="activeCategory" onClick={() => handleNavTriggerClick('Browse All The Genres')}>
                    {activeCategory || "All Genres"} 
                </div>
            </div>

            {items.map((item, index) => (
            <React.Fragment key={item.id}>
                <div className='albumsWrapper'>
                <div className='albumsElement'>
                    <div className='spotifyPlayer'>
                    {visiblePlayerFrame === item ? (
                        <PlayerFrame link={item.Link} title={item.title} />
                    ) : (
                        <img src={item.Cover} className="albumCover" alt={`Cover for ${item.title}`} onClick={() => handlePlayClick(item)}/>
                    )}
                    </div>
                    <div className='textElement'>
                    <h1 className='rightText title'>
                        {item.Artist}
                    </h1>
                    <h3 className='rightText album'>
                        {item.Album}
                    </h3>
                    <p className='rightText year'>
                        {item.Year}
                    </p>
                    <p className='rightText year'>
                      {!activeCategory && (
                        <>{item.Category}</>
                      )}
                    </p>
                    <div className='flexTriggers marginTop'> 
                        <button className="playButton" onClick={() => handlePlayClick(item)}>
                        {visiblePlayerFrame === item ? (
                            <PauseIcon />
                        ) : (
                            <PlayIcon />
                        )}
                        </button>
                        <button className='playButton shareButton' onClick={() => handleShareClick(item)}>
                            <ShareButton />
                        </button>
                    </div>
                    </div>
                </div>
                </div>
                {/* Insert advertising after the third item */}
                {index === 2 && !isPWAInstalled && (
                <div className='albumsWrapper advertisingWrapper' key={`advertising_${index}`}>
                    <div className='adElement'>
                    <h1 className='rightText title appInstall'>
                        Get daily Indie releases?
                    </h1>
                    <h3 className='rightText album'>
                        Install Chillmoon app and don't miss on your favorite new music!
                    </h3>        
                    <button className='installButton' onClick={handleInstallClick}>Install Chillmoon</button>
                    </div>
                </div>
                )}
            </React.Fragment>
            ))}
        </div>
    </div>
  );
};

export default MainComponent;