import React from 'react';

function PlayIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.17919 0.330738C1.82685 -0.444979 0.730469 0.190497 0.730469 1.74898V13.2499C0.730469 14.8099 1.82685 15.4446 3.17919 14.6696L13.2316 8.90464C14.5844 8.12864 14.5844 6.87142 13.2316 6.09561L3.17919 0.330738Z" fill="#E6E6E6"/>
    </svg>
  );
}

export default PlayIcon;