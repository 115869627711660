import React from 'react';

const Metadata = ({ title, description, ogTitle, ogDescription, ogImageUrl }) => {
  React.useEffect(() => {
    document.title = title;

    const metaTags = document.getElementsByTagName('meta');
    for (let i = 0; i < metaTags.length; i++) {
      const tag = metaTags[i];
      if (tag.getAttribute('name') === 'description') {
        tag.setAttribute('content', description);
      }
      if (tag.getAttribute('name') === 'og:title') {
        tag.setAttribute('content', ogTitle);
      }
      if (tag.getAttribute('name') === 'og:description') {
        tag.setAttribute('content', ogDescription);
      }
      if (tag.getAttribute('name') === 'og:image') {
        tag.setAttribute('content', ogImageUrl);
      }
    }
  }, [title, description, ogTitle, ogDescription, ogImageUrl]);

  return null;
};

export default Metadata;