import React from 'react';

const PlayerFrame = ({ link, title }) => {
  return (
    <iframe
      title={title} // Add a unique title for each album
      style={{ borderRadius: '20px' }}
      src={`${link}?utm_source=generator`}
      width="100%"
      height="400px"
      frameBorder="0"
      allowFullScreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    ></iframe>
  );
};

export default PlayerFrame;